/**
 * Wysiwyg
 */

import React from 'react';
import PropTypes from 'prop-types';

class Wysiwyg extends React.PureComponent {
    render() {
        const { wysiwyg, className } = this.props;

        return (
            <div
                className={className}
                dangerouslySetInnerHTML={{ __html: wysiwyg }}
            />
        );
    }
}

Wysiwyg.propTypes = {
    wysiwyg: PropTypes.string,
    className: PropTypes.string,
};

Wysiwyg.defaultProps = {
    className: 'Wysiwyg',
}

export default Wysiwyg;
